
import { defineComponent, onMounted, reactive } from "vue";
import affTicket from "@/views/still/deploy/affTicket.vue";
import chat from "@/views/still/chat/chat.vue";
import * as moment from "moment";
import { useRouter } from "vue-router";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "deploy-overview",
  components: {affTicket, chat},
  setup() {
    
    const router = useRouter();

    //moment.default.locale("fr");
    const state = reactive({
      loaderEnabledGen : true,
      loaderEnabled: true,
      myList: [] as any,
      valTicket : [] as any,
      selTicket : 0,
      topChat : 20,
      hauteurChat: 50,
    });

    onMounted(async () => {
      if (router.currentRoute.value.params.ticid) {
        affTicket(router.currentRoute.value.params.ticid);
        state.loaderEnabledGen = false;
      }
      
    });

    const affTicket = async (ticID) => {
      state.selTicket = ticID;
      let myList = await mAxiosApi.prototype.getAxios("/getTicket/" + ticID);

      myList.results.tic_staging.forEach((el) => {
        el.fichiers = JSON.parse(el.fichiers);
      })

      state.valTicket = myList.results;
      state.loaderEnabled = false;
    }

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    return {
      state,
      formatDate, 
      affTicket
    };
  },
});
